import {
  AlignItems,
  Box,
  FontWeight,
  Glyph,
  Icon,
  Menu,
  Space,
  Text,
  useTheme,
} from "@gocardless/flux-react";
import { LinkBuilder, Route } from "src/components/routing";

import { menuItemStyle } from "../OrganisationSwitcher.style";

export const GeneralMenuItem: React.FC<{
  icon: Glyph;
  title: React.ReactNode;
  route: Route;
  isMobile: boolean;
  useCustomHoverStyle?: boolean;
}> = ({ icon, title, route, isMobile, useCustomHoverStyle = false }) => {
  const { theme } = useTheme();

  return (
    <LinkBuilder route={route}>
      {(result) => (
        <Menu.Link
          {...result}
          css={menuItemStyle(theme, { isMobile, useCustomHoverStyle })}
        >
          <Box layout="flex" alignItems={AlignItems.Center} gutterH={0.5}>
            <Icon size="12px" name={icon} />
            <Space h={isMobile ? 0.25 : 1} layout="inline" />
            <Text weight={FontWeight.SemiBold}>{title}</Text>
          </Box>
        </Menu.Link>
      )}
    </LinkBuilder>
  );
};
